import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {ClassesContentWrap, ClassesContentTop} from './classes-content-area.stc'

const ClassesContentArea = (props) => {
    const {taglineCSS, subHeadingCSS, textCSS} = props;
    return (
        <ClassesContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        <ClassesContentTop>
                            <Text {...textCSS}>
                                Please contact me ahead of the session you wish to join so I can send you the Zoom link for that class.  Much of this work can be modified if I can be aware of presenting conditions ahead of time.
                                Those with mental health conditions are welcome.
                            </Text>
                            <Text {...textCSS}>
                                One-to-one sessions: On request.  Please contact me for more information.
                            </Text>
                            <Text {...textCSS}>
                                &nbsp;
                            </Text>
                            <Heading {...taglineCSS}>Offerings</Heading>
                            <Heading {...subHeadingCSS}>Bespoke coaching packages</Heading>
                            <Heading {...subHeadingCSS}>Gentle yoga</Heading>
                            <Text {...textCSS}>
                                A class for people who wish to invite ease into their system.  This class begins with simple breathing techniques to bring calmness and promote relaxation.
                                Everyone is welcome irrespective of fitness level, age, shape or orientation.
                                This is the class to look forward to where you literally get the chance to breathe freely, to soften and take your time as you explore how you are today.
                            </Text>
                            <Heading {...subHeadingCSS}>Well Woman Yoga Therapy</Heading>
                            <Text {...textCSS}>
                                A form of yoga designed for women <span className="font-weight-bold">by</span> women, to bring overall health and balance.  This class helps women by working with our body and cyclical nature.  I am thrilled to have the honour of teaching this work and to facilitate bringing women into connection with the wisdom of their body. The class uses movements and practices that honour and explore all of our aspects.
                                For every stage of womanhood, this work supports you.<br/>&nbsp;<br/>
                                Please try this class to find greater joy, peace, connection and vitality.  Bliss awaits.                            </Text>
                            <Heading {...subHeadingCSS}>Introduction to Movement</Heading>
                            <Text {...textCSS}>
                                For people who do not do yoga, or feel it is perhaps not for them, yet.
                                This class teaches a variety of simple breathing techniques to energise and/or calm the body and mind, plus plenty of time to practice.
                                Participants can look forward to a fuller explanation of movement cues, and how to embody these cues in their body.  The pace of the class is steady, allowing individuals the opportunity to explore new movements and how they feel to them.  If you are interested in increasing range of motion, bringing calm into your being, combating stiffness and building core stability in a safe and informative environment then I look forward to meeting you.
                            </Text>
                            <Heading {...subHeadingCSS}>Postnatal yoga</Heading>
                            <Text {...textCSS}>
                                A postnatal yoga class flows with its participants, especially the small ones!  Soothing breath work, pelvic floor strengthening, sound practice and postural support are included throughout a class alongside body movement.  Deepest relaxation in the form of yoga nidra or supported feeding positions can bring comfort and relief helping facilitate peace and healing.
                                The postnatal body is a vulnerable place.  A nourishing and healing postnatal yoga class can benefit both mum and baby after the birth.
                            </Text>
                            <Heading {...subHeadingCSS}>Pregnancy yoga</Heading>
                            <Text {...textCSS}>
                                In prenatal yoga, specific flows and postures are designed to help you to feel calm, nurtured and energised throughout your pregnancy.  Yoga during pregnancy gives an opportunity for you to connect with your baby and optimise you own wellbeing.  A balance of breath work, restorative poses and movement can help you to feel supported, strong and grounded as you prepare for labour and birth.  Many of the less well-known practices of yoga are found in this pregnancy yoga class.  Techniques which support the pregnant woman and her unborn baby can be enjoyed throughout pregnancy and can help to aid birth.
                            </Text>
                            <Text {...textCSS}>
                                The importance of finding peace and calm (when pregnant) aids babies in building healthy emotional and physical foundations, thereby aiding their development.
                                I followed an Ayurvedic pregnancy whilst pregnant, followed a very healthy diet with weekly reflexology sessions.  I did strength training until my third trimester and found it helped with nausea.  I trained my body, learned and practiced effective breathing techniques for pregnancy and birth.  I felt the best I have ever felt in pregnancy, part of this was due to high levels of self-care and tenderness I felt towards myself and little one.  I know how good pregnancy can feel and hope to bring this to all!  I feel that being pregnant and becoming a mother changed me as a person, entirely.  I am not sure anything else has this much of an impact in my life.  Helping women to be guided through this time should not to be underestimated.  I feel it is important to help women to feel cared for and nurtured at this time, and to prepare them for the next step in their journey.
                            </Text>
                        </ClassesContentTop>
                    </Col>
                </Row>
            </Container>
        </ClassesContentWrap>
    )
}

ClassesContentArea.defaultProps = {
    titleCSS: {
        as: "h5",
        fontSize: [1],
        textTransform: "capitalize",
        mb: "17px"
    },
    headingCSS: {
        as: 'h3',
        color: 'secondaryColor',
        fontSize: '1.8em',
        // lineHeight: 1.375,
        mb: '0px',
        mt: '55px',
    },
    subHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        fontSize: '1.5em',
        // lineHeight: 1.375,
        mb: '10px',
        mt: '25px',
    },
    taglineCSS: {
        color: "secondaryColor",
        fontSize: [4, 5],
        fontWeight: "bold",
        lineHeight: [1.4, 1.375],
        mt: '30px'
    },
    textCSS: {
        mb: '10px'
    }
}

export default ClassesContentArea


  