import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import ClassesTitleArea from '../containers/classes/classes-title-area'
import ClassesContentArea from '../containers/classes/classes-content-area'

const ClassesPage = () => (
  <Layout>
    <SEO 
        title="Classes" 
        description="Classes, private sessions and workshops"
        keywords={["classes", "private session", "workshop"]}
    />
    <Header/>
    <div className="main-content">
        <ClassesTitleArea/>
        <ClassesContentArea/>
    </div>
    <Footer/>
  </Layout>
) 

export default ClassesPage
